@use '../abstract/mixins' as mixin;
@use "../abstract/responsive" as rwd;

$c-amaranth: #E51553;
$c-black: #000000;
$c-botticelli: #D5DDE8;
$c-dove-gray: #656565; 
$c-gallery: #EEEEEE; 
$c-ghost: #C7C9D5; 
$c-white: #FFFFFF; 

$transition: all .4s ease;  

// Main news section
.mainNews {

  .onlyMainArticle,
  .horizontalMobileSmall {
    margin-left: 10px;
  }

  .newsSection > .boxListSection,
  [data-slide-txt] {
    > li {
      @include rwd.breakpoint(mobile) {
        &:nth-of-type(odd) {
          padding-right: 0 !important;
        }

        &:nth-of-type(even) {
          padding-left: 0 !important;
          padding-right: 0;
        }
      }
    }
  }

  .boxListSection {
    @include mixin.flexValue();
    padding: 0;
    height: max-content;

    @include rwd.breakpoint(tabletmobile) {
      .imgWrapper {
        flex: 1;
      }

      .boxData {
        flex: 2;

        .titleTxt {
          font-weight: 600;
        }
      }

      .important {
        @include rwd.breakpoint(tabletmobile) {
          .txtCategoryLabel {
            color: $c-amaranth;
            background-color: $c-white;
            line-height: 11px;
          }
        }
      }
    }

    .imgWrapper {
      @include rwd.breakpoint(smallPhones) {
        padding-right: 0;
      }
    }

    li,
    .roleLi {
      margin-bottom: 0;

      @include rwd.breakpoint(tabletmobile) {
        padding-left: 0;
      }
    }

    .noPhotoArticle {
      @include rwd.breakpoint(smallUp) {
        margin-bottom: 10px;
      }

      &.hideMobile {
        @include rwd.breakpoint(tabletmobile) {
          display: none;
        }
      }

      .titleLink {
        @include rwd.breakpoint(smallUp) {
          border-bottom: none;
        }
      }

      .topLine {
        @include rwd.breakpoint(smallUp) {
          padding: 15px 0;
          position: relative;

          &:before {
            @include mixin.position(absolute, 0, 0, false, 0);
            display: block;
            content: "";
            height: 1px;
            width: 100%;
            background-color: $c-botticelli;
          }
        }
      }

      .titleTxt {
        @include mixin.font(16px, 24px, 400);
        @include mixin.showLines(2);
        max-height: 48px;
        margin: 0;
      }
    }

    .marginBottom {
      @include rwd.breakpoint(smallUp) {
        margin-bottom: 20px;
      }
    }

    &.newsSection__mainSmallerTiles {
      @include rwd.breakpoint(tabletmobile) {
        padding-right: 5px !important;
      }
    }
  }

  .section {
    [data-hidden-link] > li {
      @include rwd.breakpoint(mobile) {
        &:nth-of-type(odd) {
          padding-right: 15px !important;
        }

        &:nth-of-type(even) {
          padding-left: 15px !important;
        }
      }
    }

    .boxListSection {
      margin-bottom: 20px;

      @include rwd.breakpoint(mobile) {
        margin-bottom: 10px;
      }

      li,
      .roleLi {
        @include rwd.breakpoint(smallUp) {
          margin-bottom: 20px;
        }

        &.hideBottomLine {
          .boxWithImg {
            border-bottom: none;
          }
        }

        &:last-of-type {
          .boxWithImg {
            border-bottom: none;
          }
        }
      }
    }

    .readMore {
      &:after {
        @include rwd.breakpoint(tabletmobile) {
          top: 40%;
          right: 25px;
        }
      }

      &.arrow {
        &:after {
          @include rwd.breakpoint(tabletmobile) {
            transform: rotate(0deg);
          }
        }
      }
    }
    @include rwd.breakpoint(mobile) {
      .readMore.moreInfo:after {
        top: 3px;
        right: 5px;
      }
    }
  }

  .readMoreWrapper.mobileBtn {
    @include rwd.breakpoint(smallUp) {
      margin-top: -20px;
    } 
  }

  .sectionTitle.onlyMainArticle,
  .sectionTitle.horizontalMobileSmall {
    @include rwd.breakpoint(mobile) {
      padding-left: 56px;

      &::before {
        left: 0;
      }
    }

    @include rwd.breakpoint(phonesDown) {
      margin-left: 15px;
    }
  }

  .horizontalBox {
    @include rwd.breakpoint(large) {
      margin-bottom: 170px;
    }

    .boxWithImg {
      height: auto;

      @include rwd.breakpoint(large) {
        max-height: 348px;
      }

      @include rwd.breakpoint(mobile) {
        max-height: initial;
        min-height: initial;
      }

      .imgWrapper:after {
        display: none;
      }

      &.funeral,
      &.important {
        @include rwd.breakpoint(tablet) {
          margin-left: -13px;
          margin-right: -13px;
        }

        @include rwd.breakpoint(largePhones) {
          margin-left: -9px;
          margin-right: -9px;
        }

        @include rwd.breakpoint(tabletmobile) {
          border-bottom: 1px solid $c-white;
        }
      }

      &.funeral {
        background-color: transparent;

        .boxData {
          background-color: $c-black;
          margin-top: 0;
          padding: 5px 10px;
        }

        .textLabel,
        .boxTitle {
          color: $c-white;
        }
      }
    }

    .imgWrapper {
      height: auto;

      @include rwd.breakpoint(mobile) {
        height: 240px;

        img {
          object-fit: cover;
        }
      }
    }

    .boxData {
      position: initial !important;
      margin-top: 6px;

      @include rwd.breakpoint(tabletmobile) {
        padding: 0 15px 20px;
      }

      @include rwd.breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;
      }

      @include rwd.breakpoint(mobile) {
        padding-bottom: 10px;
        margin-top: 10px;
      }
    }

    .boxTitle,
    .textLabel {
      color: initial;
    }

    .boxTitle {
      @include mixin.showLines(3);

      @include rwd.breakpoint(mobileUp) {
        @include mixin.font(30px, 40px, 700);
      }
    }

    &.horizontalMobile {
      @include rwd.breakpoint(tabletmobile) {
        .boxWithImg {
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid $c-gallery;

          .textLabel {
            display: none;
          }

          .imgWrapper {
            flex: 1;
          }

          .boxData {
            flex: 2;
            margin-left: 10px;
            margin-top: 0;
            padding: 0;
          }

          .boxTitle {
            @include mixin.showLines(4);
          }
        }
      }

      @include rwd.breakpoint(tablet) {
        .boxWithImg {
          img {
            height: 15vw;
          }

          .boxTitle {
            @include mixin.font(20px, 26px);
          }
        }
      }

      @include rwd.breakpoint(mobile) {
        .imgWrapper {
          height: unset;

          img {
            height: 20vw;
            min-height: 60px;
          }
        }

        .boxTitle {
          @include mixin.font(16px, 22px);
        }
      }

      @media screen and (min-width: 501px) and (max-width: 767px) {
        .boxWithImg img {
          max-height: 100px;
        }
      }

      @include rwd.breakpoint(phonesDown) {
        .boxWithImg {
          margin-left: 0;
          margin-right: 0;
        }
      }
      @media screen and (min-width: 450px) and (max-width: 500px) {
        .boxWithImg img {
          max-height: 85px;
        }
      }

      @media screen and (max-width: 449px) {
        .boxWithImg img {
          max-height: 75px;
        }
      }
    }
  }

  .boxBgWithTitle {
    &.infoWrapper {
      @include rwd.breakpoint(tabletmobile) {
        padding: 10px 16px 5px;
      }

      .sectionTitle {
        padding-right: 30px;

        @include rwd.breakpoint(tabletmobile) {
          padding-left: 0;

          &::before {
            display: none;
          }
        }
      }

      .boxListSection {
        margin-bottom: 0;
      }

      &.isExpanded {
        .input + .slider:before {
          background-color: var(--main-color);
        }

        .input + .slider:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
      }

      .editAcmsSection {
        top: 10px;
        left: 26px;
      }

      @include rwd.breakpoint(tablet) {
        .readMore:after {
          top: 3px;
          right: 5px;
          transform: rotate(90deg);
        }
      }
    }

    .readMoreWrapper:after {
      display: none;
    }

    .alert {
      &__topBar {
        display: flex;
        justify-content: space-between;

        .readMoreWrapper {
          margin-top: 0;
          cursor: pointer;
        }
      }

      &__bottomBar {
        padding-top: 20px;

        @include rwd.breakpoint(tabletmobile) {
          padding-top: 0;

          &.boxBgWithTitle--wrapper {
            .displayMoreInfo {
              display: block;
            }

            &.expanded {
              .readMore:after {
                @include rwd.breakpoint(tablet) {
                  transform: rotate(270deg);
                }
              }
            }
          } 
        }
      }

      &__switchWrapper {
        @include rwd.breakpoint(tabletmobile) {
          display: none;
        }

        .switchBox {
          display: flex;
          align-items: center;
          cursor: pointer;

          .readMore {
            padding-right: 0;
            padding-left: 12px;
            width: 87px;

            &:after {
              display: none;
            }
          }
        }

        .switch {
          position: relative;
          display: inline-block;
          width: 31px;
          height: 11px;

          .input {
            opacity: 0;
            width: 0;
            height: 0;
          }

          .slider {
            @include mixin.absoluteCover();
            cursor: pointer;
            background-color: $c-ghost;
            transition: $transition;

            &:before {
              @include mixin.position(absolute, false, 15px, -3px);
              content: "";
              height: 18px;
              width: 18px;
              background-color: $c-dove-gray;
              transition: $transition;
            }

            &.round {
              border-radius: 34px;

              &:before {
                border-radius: 100%;
              }
            }
          }
        }
      }
    }
  }

  .advertisement__title {
    @include rwd.breakpoint(smallUp) {
      text-align: left;
    }
  }

  .aside {
    @include rwd.breakpoint(tablet) {
      margin-top: 20px;
    }

    .autoPromoBox:first-of-type {
      margin-top: 0;
    }
  }

  .c-qualityBox {
    .readMore {
      &:after {
        top: 2px;
        right: 8px;
        transform: rotate(0deg);
      }
    }
  }

  .newsSection {
    &__mainBigTile {
      .boxWithImg {
        .boxData {
          .txtCategoryLabel {
            width: fit-content;
          }
        }
        &.boxWithSomeIcon {
          .boxData {
            .txtCategoryLabel {
              left: 50px;
            }
          }
        }
      }
    }
  }

  .onlyMainArticleBox {
    @include rwd.breakpoint(tabletmobile) {
      .boxListSection {
        .roleLi:last-of-type {
          .boxWithImg.boxWithImgHorizontal {
            border-bottom: none;
          }
        }
      }
    }

    @include rwd.breakpoint(mobile) {
      margin-top: 20px;
    }

    @include rwd.breakpoint(phonesDown) {
      .horizontalBox .boxWithImg {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .readMoreWrapper.mobileBtn {
      margin-top: 10px;
      margin-bottom: 0;

      @include rwd.breakpoint(tabletmobile) {
        .readMore:after {
          top: 40%;
          right: 25px;
        }
      }
    }
  }

  .editAcmsSection {
    /* top: -24px; */
  }
  &.twoColumns {
    margin-top: 0;
    
    @include rwd.breakpoint(smallUp) {
      padding-bottom: 10px;
    }
  }
}
